const GUTTING_WASTE = 0.17;

/* Old, should be deleted */
const settings = {
    guttingWaste: GUTTING_WASTE,
    slaughteredFactor: 1 - GUTTING_WASTE,
    weightAverageDayDiffThreshold: 5,
    primaryColor: '#5bb784',
    secondaryColor: '#547fa2',
    orangeColor: '#FF7E0E',
    blueColor: '#00E6F0',
    lightBlueColor: '#66CCEE',
    darkGrayColor: '#4A485F',
    purpleColor: '#800080',
    warningColor: '#C65461',
    abnormalPitchThreshold: 10,
    abnormalRollThreshold: 10,
    abnormalMovementScoreThreshold: 0.2,
    dayFromHour: 7,
    nightFromHour: 18,
    swimspeed: {
        low_speed_threshold: 0.2,
        medium_speed_threshold: 1.5,
        high_speed_threshold: 2.0,
    },
    woundGrowthLimit: 0.025,
};

type Colors<T = string> = {
    primary: T;
    secondary: T;
    highlight: T;
    tertiery: T;
    light: T;
    dark: T;
    indigo: T;
};

export type FishTypeColors<T = string> = {
    salmon: T;
    rainbow_trout: T;
    trout: T;
};

type DayPartTagColors<T = string> = {
    all: T;
    day: T;
    night: T;
    short_day: T;
    trend_line: T;
};

type MinMaxAvgColors<T = string> = {
    min: T;
    max: T;
    avg: T;
};

type StatusColors<T = string> = {
    positive: T;
    negative: T;
    neutral: T;
};

type AlertColors<T = string> = {
    danger: T;
    warning: T;
    success: T;
    neutral: T;
};

type HeightLengthColors<T = string> = {
    height: T;
    length: T;
};

type FishHealthColors<T = string> = {
    noWound: T;
    active: T;
    healing: T;
    large: T;
    medium: T;
    small: T;
    singleWound: T;
    multipleWounds: T;
    temperature: T;
};

type MaturationColors<T = string> = {
    no: T;
    started: T;
    full: T;
};

type GraphColors<T = string> = {
    fishCount: T;
    adult_female: T;
    mobile: T;
    caligus: T;
    temperature: T;
};

type SceneLabelTagsColors<T = string> = {
    dew: T;
    rope: T;
    net: T;
    ufo: T;
    backlight: T;
    shadow: T;
    badangle: T;
    tooclose: T;
    faraway: T;
    empty: T;
    chaos: T;
    singlefish: T;
    school: T;
    lice: T;
};

export enum OptoColors {
    Riverside = '#4579A3',
    OceanGreen = '#5BB784',
    Red = '#C65461',
    Yellow = '#FFD479',
    Lavender = '#696999',
    DeepCyan = '#559DA3',
    Terracotta = '#E87166',
    Olivine = '#B6C377',
    Purple = '#8D6A99',
    Orange = '#F4A46F',
    Pink = '#CE7997',
    LeafGreen = '#80B580',
    White = '#FFFFFF',
}

export const GraphColorsInOrder = [
    OptoColors.Riverside,
    OptoColors.OceanGreen,
    OptoColors.Red,
    OptoColors.Yellow,
    OptoColors.Lavender,
    OptoColors.DeepCyan,
    OptoColors.Terracotta,
    OptoColors.Olivine,
    OptoColors.Purple,
    OptoColors.Orange,
    OptoColors.Pink,
    OptoColors.LeafGreen,
];

interface Settings {
    guttingWaste: number;
    slaughteredFactor: number;
    weightAverageDayDiffThreshold: number;
    colors: Colors;
    daytagColors: DayPartTagColors;
    SceneLabelTagsColors: SceneLabelTagsColors;
    abnormalPitchThreshold: number;
    abnormalRollThreshold: number;
    abnormalMovementScoreThreshold: number;
    dayFromHour: number;
    nightFromHour: number;
    fishHealthColors: FishHealthColors;
    minMaxAvgColors: MinMaxAvgColors;
    heightLengthColors: HeightLengthColors;
    statusColors: StatusColors;
    alertColors: AlertColors;
    maturationColors: MaturationColors;
    comparingPensColors: string[];
    designSystemColors: string[];
    designSystemShades: string[];
    fishTypeName: FishTypeColors;
    graphColors: GraphColors;
    laksvel: {
        score_0?: string;
        score_1: string;
        score_2: string;
        score_3: string;
    };
}

const graphSettings: Settings = {
    guttingWaste: GUTTING_WASTE,
    slaughteredFactor: 1 - GUTTING_WASTE,
    weightAverageDayDiffThreshold: 5,
    SceneLabelTagsColors: {
        dew: '#953502',
        rope: '#b52a2c',
        net: '#c3265b',
        ufo: '#c4298c',
        backlight: '#b335bf',
        shadow: '#9848e2',
        badangle: '#7761f5',
        tooclose: '#5182f7',
        faraway: '#379ee7',
        empty: '#27b7ca',
        chaos: '#26caa7',
        singlefish: '#38d67d',
        school: '#55d960',
        lice: '#3366699',
    },
    fishHealthColors: {
        noWound: OptoColors.OceanGreen,
        active: OptoColors.Red,
        healing: '#4579A3',
        large: '#C65461',
        medium: '#4579A3',
        small: '#5BB784',
        singleWound: '#5BB784',
        multipleWounds: '#547FA2',
        temperature: '#EAF0FE',
    },
    maturationColors: {
        no: '#5bb784',
        started: '#f8d0d6',
        full: '#ff7c6a',
    },
    daytagColors: {
        all: OptoColors.OceanGreen,
        day: OptoColors.Orange,
        night: OptoColors.Purple,
        short_day: OptoColors.Pink,
        trend_line: OptoColors.Riverside,
    },
    minMaxAvgColors: {
        min: '#547fa2',
        max: '#141920',
        avg: '#5bb784',
    },
    heightLengthColors: {
        height: '#5bb784',
        length: '#547fa2',
    },
    colors: {
        primary: '#5bb784',
        secondary: '#547fa2',
        tertiery: '#00E6F0',
        highlight: '#FF7E0E',
        light: '#66CCEE',
        dark: '#4A485F',
        indigo: '#6776b9',
    },
    graphColors: {
        fishCount: '#5BB784',
        adult_female: OptoColors.Pink,
        mobile: OptoColors.LeafGreen,
        caligus: '#4579A3',
        temperature: '#9BBBD5',
    },
    statusColors: {
        positive: '#5bb784',
        negative: '#dd0004',
        neutral: '#547fa2',
    },
    alertColors: {
        danger: '#ff1f09',
        warning: '#ff7e0e',
        success: '#5bb784',
        neutral: '#547fa2',
    },
    comparingPensColors: GraphColorsInOrder,
    designSystemColors: [
        '#000000',
        '#FFFFFF',
        '#4579A3',
        '#559DA3',
        '#5BB784',
        '#696999',
        '#80B580',
        '#8D6A99',
        '#B6C377',
        '#C65461',
        '#CE7997',
        '#E87166',
        '#F4A46F',
        '#FFD479',
    ],

    designSystemShades: [
        '#FFFFFF',
        '#000000',
        '#14172D',
        '#262F54',
        '#5D6182',
        '#74799A',
        '#8B91B1',
        '#A2A9C8',
        '#B9C0DF',
        '#D0D4E9',
        '#E9EBF5',
        '#F8F8FC',
        '#FFFFFF',
    ],
    abnormalPitchThreshold: 10,
    abnormalRollThreshold: 10,
    abnormalMovementScoreThreshold: 0.2,
    dayFromHour: 7,
    nightFromHour: 18,
    fishTypeName: {
        salmon: '#5bb784',
        rainbow_trout: '#274f8c',
        trout: '#274f8c',
    },
    laksvel: {
        score_1: '#FFD479',
        score_2: '#F4A46F',
        score_3: '#E87166',
    },
};

/*
const LOW_SPEED_THRESHOLD = 0.5;
const MEDIUM_SPEED_THRESHOLD = 1.5;
const HIGH_SPEED_THRESHOLD = 2.0;
*/

function hexToRGBA(hex: string, alpha = 1): string {
    const r = Number.parseInt(hex.slice(1, 3), 16);
    const g = Number.parseInt(hex.slice(3, 5), 16);
    const b = Number.parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default settings;
export { graphSettings, settings, hexToRGBA };
