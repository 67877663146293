import { t } from 'i18next';

import { GenericPage, GenericSidebar } from '@/components/molecules/GenericSidebar';
import LICENSES from '@/config/licenses';
import { useMe } from '@/contexts/meContext';
import LocationRoutes from '@/routes/Client/Location/LocationRoutes';
import { locationSidebarOptions, pages } from '@/routes/Client/Location/pages';
import { LocationsLicenseOverview } from '@/services/types';
import { Page, PageContent } from 'components/Layout';

interface LocationLayerProps {
    clientId: number;
    locationId: number;
    clientName?: string;
    locationLicenseData?: LocationsLicenseOverview;
}

const LocationLayer = ({
    clientId,
    locationId,
    clientName = '',
    locationLicenseData = null,
}: LocationLayerProps) => {
    const { isOptoscaleAdmin } = useMe();

    const isLocationPageAvailable = (page: GenericPage) => {
        if (!page.licenses || isOptoscaleAdmin) {
            return true;
        }
        if (locationLicenseData == null) {
            return false;
        }
        if (
            page.licenses.find((x) => x === LICENSES.LICE_COUNT.MIN) ||
            page.licenses.find((x) => x === LICENSES.LICE_COUNT.MAX)
        ) {
            const hasAccessToLice =
                locationLicenseData?.licenses.liceLicense.hasValidLicense ?? false;
            if (!hasAccessToLice) {
                return false;
            }
        }
        return true;
    };

    return (
        <Page title="Location">
            <GenericSidebar
                sideBarOptions={locationSidebarOptions}
                backOption={{
                    url: `/c/${clientId}/`,
                    title: clientName ? clientName : t('Back'),
                }}
                checkIsPageAvailable={isLocationPageAvailable}
            />

            <PageContent>
                <LocationRoutes pages={pages} clientId={clientId} locationId={locationId} />
            </PageContent>
        </Page>
    );
};

export default LocationLayer;
