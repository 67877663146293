import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';

import { IconButton } from '@/components/atoms/Buttons';
import UmerBoxElementDivider from '@/components/atoms/UmerBoxElements/UmerBoxElementDivider/UmerBoxElementDivider';
import { UmerBoxElementStatusInfoWithTrend } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend';
import UmerBoxElementText from '@/components/atoms/UmerBoxElements/UmerBoxElementText';
import { localizedDateFormat } from '@/helpers/date';
import { CageStatus } from '@/services/types';
import { localeFormatNumber } from '@/utils/decimalNumberFormatter';

import styles from './BioscopeStatusBox.module.scss';

interface BioscopeStatusBoxContentProps {
    cageStatus: CageStatus;
    timezone: string;
    measurementCount: number;
    isOptoscaleAdmin?: boolean;
    toolTipNubDirection?:
        | 'up-left'
        | 'up-right'
        | 'up-center'
        | 'down-center'
        | 'down-left'
        | 'left-center'
        | 'right-center'
        | 'down-right';
    showBioscopeInspectionLink?: boolean;
    bioscopeInspectionLink?: string;
}

function BioscopeStatusBox({
    cageStatus,
    timezone,
    measurementCount,
    isOptoscaleAdmin = false,
    toolTipNubDirection = 'up-right',
    showBioscopeInspectionLink,
    bioscopeInspectionLink,
}: BioscopeStatusBoxContentProps) {
    const { t } = useTranslation();
    const history = useHistory();

    const totalCount = measurementCount;

    const [depth, setDepth] = useState<string>('');
    const [pitchAndRoll, setpitchAndRoll] = useState<string>('');
    const [numberOfMeasuredFish, setNumberOfMeasuredFish] = useState<string>('');
    const [lastMeasurement, setLastMeasurement] = useState<string>('');
    const [activeBioscopeId, setActiveBioscopeId] = useState<string>('');
    const [lastActivity, setLastActivity] = useState<string>('');

    const calculateBioscopeBoxTexts = () => {
        if (cageStatus) {
            if (cageStatus.lastData?.depthLast) {
                setDepth(
                    `${localeFormatNumber(cageStatus?.lastData?.depthLast, 1, i18n.language)} m`
                );
            } else {
                setDepth('-');
            }

            if (cageStatus?.lastData?.timestampLast) {
                setLastMeasurement(
                    `${localizedDateFormat({
                        dateString: cageStatus.lastData.timestampLast,
                        locale: i18n.language,
                    })}`
                );
            }

            if (cageStatus?.lastData?.lastAlive) {
                setLastActivity(
                    `${localizedDateFormat({
                        dateString: cageStatus.lastData.lastAlive,
                        locale: i18n.language,
                    })}`
                );
            }

            const pitchText = `${localeFormatNumber(
                cageStatus?.lastData?.pitchLast,
                1,
                i18n.language,
                '-'
            )}`;
            const rollText = `${localeFormatNumber(
                cageStatus?.lastData?.rollLast,
                1,
                i18n.language,
                '-'
            )}`;

            setpitchAndRoll(`${pitchText} , ${rollText}`);
        }

        setNumberOfMeasuredFish(`${totalCount}`);

        if (cageStatus?.currentBioscope?.id) {
            setActiveBioscopeId(`${cageStatus.currentBioscope.id}`);
        } else {
            setActiveBioscopeId('-');
        }
    };
    useEffect(() => {
        calculateBioscopeBoxTexts();
    }, []);
    useEffect(() => {
        calculateBioscopeBoxTexts();
    }, [measurementCount, timezone, cageStatus]);

    return (
        <div className={styles.boxContainer}>
            <div className={styles.informationSection}>
                <div className={styles.twoColumns}>
                    <UmerBoxElementStatusInfoWithTrend
                        title={t('Depth')}
                        info={depth}
                        toolTipContent={t('descriptions.bioscopeStatus.depth')}
                        toolTipNubDirection={toolTipNubDirection}
                    />
                    <UmerBoxElementStatusInfoWithTrend
                        title={t('Pitch, Roll')}
                        info={pitchAndRoll}
                        toolTipContent={t('descriptions.bioscopeStatus.pitchAndRoll')}
                        toolTipNubDirection={toolTipNubDirection}
                    />
                    <UmerBoxElementStatusInfoWithTrend
                        title={t('Measured fish total')}
                        info={`${totalCount}`}
                        toolTipContent={t('descriptions.bioscopeStatus.measurementCount')}
                        toolTipNubDirection={toolTipNubDirection}
                    />
                    <UmerBoxElementStatusInfoWithTrend
                        title={t('Active Bioscope')}
                        info={activeBioscopeId}
                    />
                </div>
                <UmerBoxElementStatusInfoWithTrend
                    title={t('Last measurement')}
                    info={lastMeasurement}
                    toolTipContent={t('descriptions.bioscopeStatus.lastMeasurement')}
                    toolTipNubDirection={toolTipNubDirection}
                />
            </div>

            {isOptoscaleAdmin && (
                <>
                    <UmerBoxElementDivider />
                    <UmerBoxElementText
                        text={t('Only visisble for optoscale admin users')}
                        variant={'normal'}
                    />
                    <div className={styles.twoColumns}>
                        <UmerBoxElementStatusInfoWithTrend
                            title={t('Last activity')}
                            info={lastActivity}
                            toolTipContent={t('descriptions.bioscopeStatus.lastLifesign')}
                            toolTipNubDirection={
                                toolTipNubDirection
                            }></UmerBoxElementStatusInfoWithTrend>
                    </div>
                </>
            )}

            {(isOptoscaleAdmin || showBioscopeInspectionLink) && bioscopeInspectionLink && (
                <>
                    <UmerBoxElementDivider />
                    <IconButton
                        variant={'secondary'}
                        buttonText={t('Bioscope inspection')}
                        onClick={() => history.push(bioscopeInspectionLink)}></IconButton>
                </>
            )}
        </div>
    );
}

export default BioscopeStatusBox;
