import { useTranslation } from 'react-i18next';
import { ChartDataset } from 'chart.js';

import { DatePicker, DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import { getRangeEdgesFromDataSet } from '@/components/organisms/OptoGraphs/commonOptoChartHelpers';
import { CombinedWelfareResultMapper } from '@/components/organisms/OptoGraphs/OptoBarChart/OptoBarChartMappers';
import type { ChartActionButtons } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChart';
import type { FishHealthData } from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/components/FishHealthData';
import { ImageFeedbackDto } from '@/services/types';
import { UmerBox } from 'components/atoms/UmerBox';
import {
    autoTimeScale,
    formatAxsis,
    layoutPadding,
    multipleYAxes,
    Options,
    smoothLines,
    startYAxisAtZero,
    StepSizeAxsis,
    suggestedMaxMin,
    tooltipFooterCalculationModifier,
    TypeNames,
    Unit,
    weightAndLengthUnitConverter,
} from 'components/organisms/OptoGraphs/optionsModifiers';
import {
    defaultLineChartOptions,
    FishHealthToDistributionOfWoundsMapper,
    FishHealthToSizeDistributionnMapper,
    FishHealthToTypesOfWoundsMapper,
    OptoMixedChart,
} from 'components/organisms/OptoGraphs/OptoMixedChart';

import styles from './CageWelfareGraphs.module.scss';

const DEFAULT_EQ_THRESHOLD = 0.02 / 7; //More than 2 % per week is considered a significant change

export interface CageWelfareGraphsPageContentProps {
    source: string;
    fishHealthData: FishHealthData;
    actionButtons?: ChartActionButtons;
    healthType?: string;
    isOptoscaleAdmin?: boolean;
    onCommitFalseDetection: (feedbackDto: ImageFeedbackDto) => void;
    cageFilterHandler?: (data: DateReturnObject) => void;
    inputDateObject?: DateReturnObject;
}

export const CageWelfareGraphsPageContent = ({
    source,
    fishHealthData,
    isOptoscaleAdmin = false,
    onCommitFalseDetection = () => {},
    actionButtons = {
        copyAsPng: true,
        resetZoom: true,
        downloadAsPng: true,
        fullScreen: true,
        timeScaleSwitch: false,
    },
    healthType = 'wounds',
    cageFilterHandler,
    inputDateObject,
}: CageWelfareGraphsPageContentProps) => {
    const { t } = useTranslation();
    const chartData = CombinedWelfareResultMapper({
        welfareResult: fishHealthData as FishHealthData,
        dayTags: [],
        inactiveSetLegend: ['noMaturation'],
    });

    return (
        <>
            <div className={styles.datePickerContainer}>
                <DatePicker
                    onDateChange={cageFilterHandler}
                    fromDefault={inputDateObject.dateFrom}
                    toDefault={inputDateObject.dateTo}
                />
            </div>
            <div className={styles.pageBoxLayout}>
                <UmerBox>
                    <OptoMixedChart
                        chartName={t('Types of wounds')}
                        chartData={FishHealthToTypesOfWoundsMapper({
                            fishHealth: fishHealthData,
                            inactiveSetLegend: [],
                        })}
                        actionButtons={{ ...actionButtons }}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            autoTimeScale({}),
                            layoutPadding({ padding: 0 }),
                            weightAndLengthUnitConverter({
                                type: TypeNames.percent,
                                fromUnit: Unit.percent,
                                toUnit: Unit.percent,
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                            }),
                            tooltipFooterCalculationModifier({
                                t,
                                operation: 'SUM',
                                unit: Unit.percent,
                                showTotalFish: true,
                                fishHealthData: fishHealthData,
                                exclude: ['temperature'],
                            }),
                            StepSizeAxsis({ stepSize: 0.5 }),
                            multipleYAxes({
                                left: {
                                    unit: Unit.percent,
                                    text: t('Wounds'),
                                },
                                right: { unit: Unit.celcius, text: t('Temperature') },
                            }),
                            suggestedMaxMin({
                                min: 0,
                                max: 20,
                                axsisID: 'yAxisRight',
                            }),
                            startYAxisAtZero({ axsisID: 'yAxisLeft' }),
                            formatAxsis({
                                axsisID: 'yAxisLeft',
                                tickType: TypeNames.percent,
                                descimals:
                                    getRangeEdgesFromDataSet(
                                        FishHealthToTypesOfWoundsMapper({
                                            fishHealth: fishHealthData,
                                            inactiveSetLegend: [],
                                        }).datasets as ChartDataset<'line'>[],
                                        ['Active', 'Healing']
                                    ).largest > 3
                                        ? 0
                                        : 1,
                            }),

                            formatAxsis({
                                axsisID: 'yAxisRight',
                                tickType: TypeNames.celcius,
                                descimals: 0,
                            }),
                            smoothLines({})
                        )}
                    />
                </UmerBox>

                <UmerBox>
                    <OptoMixedChart
                        chartName={t('Size distribution')}
                        chartData={FishHealthToSizeDistributionnMapper({
                            fishHealth: fishHealthData as FishHealthData,
                            inactiveSetLegend: [],
                        })}
                        actionButtons={{ ...actionButtons }}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            autoTimeScale({}),
                            layoutPadding({ padding: 0 }),
                            weightAndLengthUnitConverter({
                                type: TypeNames.percent,
                                fromUnit: Unit.percent,
                                toUnit: Unit.percent,
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                            }),
                            tooltipFooterCalculationModifier({
                                t,
                                operation: 'SUM',
                                unit: Unit.percent,
                                showTotalFish: true,
                                fishHealthData: fishHealthData,
                                exclude: ['temperature'],
                            }),
                            StepSizeAxsis({ stepSize: 0.5 }),
                            multipleYAxes({
                                left: {
                                    unit: Unit.percent,
                                    text: t('Wounds'),
                                },
                                right: { unit: Unit.celcius, text: t('Temperature') },
                            }),
                            suggestedMaxMin({
                                min: 0,
                                max: 20,
                                axsisID: 'yAxisRight',
                            }),
                            startYAxisAtZero({ axsisID: 'yAxisLeft' }),
                            formatAxsis({
                                axsisID: 'yAxisLeft',
                                tickType: TypeNames.percent,
                                descimals: 1,
                            }),
                            formatAxsis({
                                axsisID: 'yAxisRight',
                                tickType: TypeNames.celcius,
                                descimals: 0,
                            }),
                            smoothLines({})
                        )}
                    />
                </UmerBox>

                <UmerBox>
                    <OptoMixedChart
                        chartName={t('Distribution of wounds')}
                        chartData={FishHealthToDistributionOfWoundsMapper({
                            fishHealth: fishHealthData as FishHealthData,
                            inactiveSetLegend: [],
                        })}
                        actionButtons={{ ...actionButtons }}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            autoTimeScale({}),
                            weightAndLengthUnitConverter({
                                type: 'weight',
                                fromUnit: Unit.percent,
                                toUnit: Unit.percent,
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                            }),
                            startYAxisAtZero({}),
                            layoutPadding({ padding: 0 }),
                            tooltipFooterCalculationModifier({
                                t,
                                operation: 'SUM',
                                unit: Unit.percent,
                                showTotalFish: true,
                                fishHealthData: fishHealthData,
                            }),
                            StepSizeAxsis({ stepSize: 0.5 }),
                            multipleYAxes({
                                left: {
                                    unit: Unit.percent,
                                    text: t('Wounds'),
                                },
                                right: { unit: Unit.celcius, text: t('Temperature') },
                            }),
                            suggestedMaxMin({
                                min: 0,
                                max: 20,
                                axsisID: 'yAxisRight',
                            }),
                            startYAxisAtZero({ axsisID: 'yAxisLeft' }),
                            formatAxsis({
                                axsisID: 'yAxisLeft',
                                tickType: TypeNames.percent,
                                descimals: 1,
                            }),
                            formatAxsis({
                                axsisID: 'yAxisRight',
                                tickType: TypeNames.celcius,
                                descimals: 0,
                            }),
                            smoothLines({})
                        )}
                    />
                </UmerBox>

                {/*<UmerBox>*/}
                {/*    <OptoMixedChart*/}
                {/*        chartName={t('fishHealthIndicators.skinSpeckles')}*/}
                {/*        chartData={FishHealthToSkinSpecklesMapper({*/}
                {/*            fishHealth: fishHealthData,*/}
                {/*            inactiveSetLegend: [],*/}
                {/*        })}*/}
                {/*        actionButtons={{ ...actionButtons }}*/}
                {/*        chartOptions={Options(defaultLineChartOptions).modify(*/}
                {/*            autoTimeScale({}),*/}
                {/*            startYAxisAtZero({}),*/}
                {/*            weightAndLengthUnitConverter({*/}
                {/*                type: TypeNames.percent,*/}
                {/*                fromUnit: Unit.percent,*/}
                {/*                toUnit: Unit.percent,*/}
                {/*            }),*/}
                {/*            formatAxsis({*/}
                {/*                axsisID: 'y',*/}
                {/*                tickType: TypeNames.percent,*/}
                {/*                descimals: 0,*/}
                {/*            }),*/}
                {/*            layoutPadding({ padding: 0 })*/}
                {/*        )}*/}
                {/*    />*/}
                {/*</UmerBox>*/}
            </div>
        </>
    );
};
