import { useEffect } from 'react';
import { useCage } from 'contexts/cage-context';
import { t } from 'i18next';

import { Page, PageContent } from '@/components/Layout';
import { GenericPage, GenericSidebar, SidebarType } from '@/components/molecules/GenericSidebar';
import { useMe } from '@/contexts/meContext';
import { createCageUrl } from '@/helpers/urlGenerator';
import useCageIsPageAvailable from '@/hooks/is-page-available-cage-layer';
import CageRoutes from '@/routes/Client/Cage/CageRoutes';
import pages, { cageSidebarOptions } from '@/routes/Client/Cage/pages';

interface CageLayerProps {
    clientId: number;
    locationId: number;
    cageId: number;
}

const CageLayer = ({ clientId, locationId, cageId }: CageLayerProps) => {
    const { state, isOptoscaleAdmin } = useMe();

    const { loading: cageIsLoading, isTrout } = useCage();

    const cageLayerIsPageAvailable = useCageIsPageAvailable();

    const cageSidebarOptionsForTrout = cageSidebarOptions.filter(
        (page) => !(isTrout && page.page.label === 'Laksvel')
    );

    let locationUrl = '';
    let locationName = '';
    if (clientId && locationId) {
        locationUrl = `/c/${clientId}/location/${locationId}/`;
        const client = state?.clients?.find((x) => x.id === clientId);
        if (client) {
            const location = client.locations?.find((x) => x.id === locationId);
            if (locationId) {
                locationName = location.name;
            }
        }
    }

    let cageLayerUrlPath = '';
    if (clientId && locationId && cageId) {
        cageLayerUrlPath = createCageUrl(clientId, locationId, cageId);
    }

    const checkPageAvailableCageLayer = (page: GenericPage) => {
        return isOptoscaleAdmin ? true : cageLayerIsPageAvailable(page);
    };

    const checkPageAvailableWhileLoading = (page: GenericPage) => {
        if (isOptoscaleAdmin) {
            return true;
        }

        return !(page.licenses?.length > 0);
    };

    return (
        <>
            <Page title="Cage">
                <GenericSidebar
                    sideBarOptions={cageSidebarOptionsForTrout}
                    layerUrlPrefix={cageLayerUrlPath}
                    backOption={
                        locationUrl
                            ? {
                                  url: locationUrl,
                                  title: locationName ? locationName : t('Back'),
                              }
                            : null
                    }
                    checkIsPageAvailable={
                        cageIsLoading ? checkPageAvailableWhileLoading : checkPageAvailableCageLayer
                    }
                />
                <PageContent>
                    <CageRoutes
                        pages={pages}
                        clientId={clientId}
                        locationId={locationId}
                        cageId={cageId}
                    />
                </PageContent>
            </Page>
        </>
    );
};

export default CageLayer;
export { CageLayer };
