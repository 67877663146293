import { RouteComponentProps } from 'react-router-dom';

import { LaksvelShieldSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { GenericPage } from '@/components/molecules/GenericSidebar';
import { SidebarPageOptionObject } from '@/components/molecules/GenericSidebar/SidebarPageOptionObjects';
import LICENSES from '@/config/licenses';
import { DailyLaksvelResult } from '@/services/types';

import { Cataract } from './Cataract';
import { Emaciation } from './Emaciation';
import { EyeDamage } from './EyeDamage';
import { FinnCondition } from './FinnCondition';
import { FirstImpression } from './FirstImpression';
import { GillLidd } from './GillLidd';
import { JawDeformity } from './JawDeformity';
import { LaksvelMaturation as Maturation } from './Maturation';
import { ScaleLoss } from './ScaleLoss';
import { SkinBleeding } from './SkinBleeding';
import { SnoutWound } from './Snoutwound';
import { SpineDeformities } from './SpineDeformities';
import { Wounds } from './Wounds';

export {
    FirstImpression,
    Emaciation,
    Cataract,
    FinnCondition,
    GillLidd,
    JawDeformity,
    Maturation,
    ScaleLoss,
    SnoutWound,
    Wounds,
    EyeDamage,
    SpineDeformities,
    SkinBleeding,
};

export interface LaksvelRouterPageProps extends RouteComponentProps {
    pageLoading?: boolean;
    pagedata?: DailyLaksvelResult[] | undefined;
}

export type Pages = GenericPage[];

const pages: Pages = [
    {
        id: 1,
        hidden: false,
        label: 'Laksvel',
        path: '',
        page: FirstImpression,
        params: {
            i18n: 'first_impression',
        },
        licenses: [LICENSES.LAKSVEL],
        image: LaksvelShieldSVG,
    },
    {
        parentId: 1,
        id: 12,
        hidden: false,
        label: 'Maturation',
        path: '/maturity',
        page: Maturation,
        params: {
            i18n: 'maturity',
        },
        licenses: [LICENSES.LAKSVEL],
    },
    {
        parentId: 1,
        id: 13,
        hidden: false,
        label: 'Body wounds',
        path: '/wounds',
        page: Wounds,
        params: {
            i18n: 'wounds',
        },
        licenses: [LICENSES.LAKSVEL],
    },
    {
        parentId: 1,
        id: 14,
        hidden: false,
        label: 'Emaciation',
        path: '/emaciation',
        page: Emaciation,
        params: {
            i18n: 'emaciation',
        },
        licenses: [LICENSES.LAKSVEL],
    },
    {
        parentId: 1,
        id: 15,
        hidden: false,
        label: 'Snout wounds',
        path: '/snout-wounds',
        page: SnoutWound,
        params: {
            i18n: 'snout_wounds',
        },
        licenses: [LICENSES.LAKSVEL],
    },
    {
        parentId: 1,
        id: 16,
        hidden: false,
        label: 'Gill lid',
        path: '/gill-lid',
        page: GillLidd,
        params: {
            i18n: 'gill_lid',
        },
        licenses: [LICENSES.LAKSVEL],
    },
    {
        parentId: 1,
        id: 17,
        hidden: false,
        label: 'Deformed jaw',
        path: '/deformed-jaw',
        page: JawDeformity,
        params: {
            i18n: 'deformed_jaw',
        },
        licenses: [LICENSES.LAKSVEL],
    },
    {
        parentId: 1,
        id: 18,
        hidden: false,
        label: 'Cataract',
        path: '/cataract',
        page: Cataract,
        params: {
            i18n: 'cataract',
        },
        licenses: [LICENSES.LAKSVEL],
    },
    {
        parentId: 1,
        id: 19,
        hidden: false,
        label: 'Eye damage',
        path: '/eye-damage',
        page: EyeDamage,
        params: {
            i18n: 'eye_damage',
        },
        licenses: [LICENSES.LAKSVEL],
    },
    {
        parentId: 1,
        id: 20,
        hidden: false,
        label: 'Fin status',
        path: '/fin-status',
        page: FinnCondition,
        params: {
            i18n: 'fin_damage',
        },
        licenses: [LICENSES.LAKSVEL],
    },
    {
        parentId: 1,
        id: 21,
        hidden: false,
        label: 'Deformity',
        path: '/deformity',
        page: SpineDeformities,
        params: {
            i18n: 'deformity',
        },
        licenses: [LICENSES.LAKSVEL],
    },
    {
        parentId: 1,
        id: 22,
        hidden: false,
        label: 'Scale loss',
        path: '/scale-loss',
        page: ScaleLoss,
        params: {
            i18n: 'scale_loss',
        },
        licenses: [LICENSES.LAKSVEL],
    },
    {
        parentId: 1,
        id: 23,
        hidden: false,
        label: 'Skin bleeding',
        path: '/skin-bleeding',
        page: SkinBleeding,
        params: {
            i18n: 'skin_bleeding',
        },
        licenses: [LICENSES.LAKSVEL],
    },
].map((page, index) => ({ key: index, ...page }));

export const laksvelSidebarOptions: SidebarPageOptionObject[] = pages.map((item) => {
    return {
        page: item,
        url: '',
        active: false,
    };
});

export default pages;
export { pages };
