import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
    DailyLiceCount,
    ImageFeedbackDto,
    Last3DaysResult,
    TemperatureResult,
    WeeklyLiceCount,
} from 'services/types';

import UmerBox from '@/components/atoms/UmerBox';
import { DatePicker, DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import { defaultBarChartOptions, OptoBarChart } from '@/components/organisms/OptoGraphs';
import Options, {
    autoTimeScale,
    categoryScale,
    formatTooltip,
    startYAxisAtZero,
    StepSizeAxsis,
    suggestedMaxMin,
    TypeNames,
} from '@/components/organisms/OptoGraphs/optionsModifiers';
import {
    DailyLiceCountToLiceCountBarChartMapper,
    DailyLiceCountToLicePerFishCountBarChartMapper,
} from '@/components/organisms/OptoGraphs/OptoBarChart/OptoBarChartMappers';
import { ImageState } from '@/hooks/image-state';

//import LiceOverview from './components/LiceOverview';
import styles from './CageLiceGrahps.module.scss';

//when changing the name in side GRAPH_LABELS_YAXIS array,
//should also change the name in AdultLiceTemperatureGraph.jsx file
const GRAPH_LABELS = [
    {
        label: 'Adult Female Lice',
        axis: 'y-axis-left',
        dataLabel: 'femaleAvg',
        licenseMaxRequired: false,
    },
    {
        label: 'Mobile Lice',
        alxis: 'y-axis-left',
        dataLabel: 'movingStageAvg',
        licenseMaxRequired: true,
    },
    {
        label: 'Caligus elongatus',
        axis: 'y-axis-left',
        dataLabel: 'caligusElongatusAvg',
        licenseMaxRequired: true,
    },
    {
        label: 'Temperature',
        axis: 'y-axis-right',
        dataLabel: 'temperature',
        licenseMaxRequired: true,
    },
] as const;

const COLORS = ['#5bb784', '#9dd4b6', '#354d78', 'rgba(203, 217, 252, 0.4)'];

interface CageLiceGraphsPageContentProps {
    last200: Last3DaysResult & {
        count?: number;
        countNoLice?: number;
        dayPart?: string;
        daypartId?: number;
        total?: number;
    };
    showMaximumLiceInformation: boolean;
    liceCount: DailyLiceCount[];
    liceCountOverviewWeekly: WeeklyLiceCount[];
    showLiceImagesForClient?: boolean;
    isOptoscaleAdmin?: boolean;
    measurementSource?: 'raw' | 'legacy';
    imageState: ImageState;
    onCommitImageFeedbackDto?: (imageFeedbackDto: ImageFeedbackDto) => void;
    cageFilterHandler?: (data: DateReturnObject) => void;
    inputDateObject?: DateReturnObject;
    temperature: TemperatureResult[];
    weeklyLice: WeeklyLiceCount[];
}

const CageLiceGraphsPageContent = ({
    last200,
    liceCount,
    liceCountOverviewWeekly,
    showMaximumLiceInformation,
    isOptoscaleAdmin = false,
    onCommitImageFeedbackDto = (imageFeedbackDto: ImageFeedbackDto) => {},
    imageState,
    cageFilterHandler,
    inputDateObject,
    temperature,
    weeklyLice,
    measurementSource = 'legacy',
}: CageLiceGraphsPageContentProps) => {
    const { t } = useTranslation();
    const graphLabels = GRAPH_LABELS.filter((licenseCheck) => {
        if (!licenseCheck.licenseMaxRequired) return true;
        if (showMaximumLiceInformation) return showMaximumLiceInformation;
        return false;
    });

    const LicePerFishDailyDataSets = DailyLiceCountToLicePerFishCountBarChartMapper({
        liceCount: liceCount,
        weekly: false,
        measurementSource: measurementSource,
        inactiveSetLegend: ['caligusElongatusAvg', 'movingStageAvg'],
        chartLiceTypes: ['femaleAvg'],
    });

    const LicePerFishWeeklyDataSets = DailyLiceCountToLicePerFishCountBarChartMapper({
        liceCount: weeklyLice,
        weekly: true,
        measurementSource: measurementSource,
        inactiveSetLegend: [],
    });

    const stepSizeFishCount =
        liceCount?.reduce((maxTotal, count) => {
            return count.total > maxTotal ? count.total : maxTotal;
        }, 0) > 1000
            ? 500
            : 100;

    return (
        <>
            <div className={styles.datePickerContainer}>
                <DatePicker
                    onDateChange={cageFilterHandler}
                    fromDefault={inputDateObject.dateFrom}
                    toDefault={inputDateObject.dateTo}
                />
            </div>
            <div className={styles.pageBoxLayout}>
                <UmerBox>
                    <OptoBarChart
                        chartData={DailyLiceCountToLiceCountBarChartMapper({
                            liceCount: liceCount,
                        })}
                        chartName={t('Fish count')}
                        actionButtons={{
                            copyAsPng: true,
                            resetZoom: true,
                            downloadAsPng: true,
                            fullScreen: true,
                            timeScaleSwitch: false,
                        }}
                        chartOptions={Options(defaultBarChartOptions).modify(
                            autoTimeScale({}),
                            StepSizeAxsis({
                                stepSize: stepSizeFishCount,
                                axsisID: 'y',
                            })
                        )}
                    />
                </UmerBox>

                <UmerBox>
                    <OptoBarChart
                        chartData={LicePerFishDailyDataSets}
                        chartName={t('Lice per fish, daily')}
                        actionButtons={{
                            copyAsPng: true,
                            resetZoom: true,
                            downloadAsPng: true,
                            fullScreen: true,
                            timeScaleSwitch: false,
                        }}
                        chartOptions={Options(defaultBarChartOptions).modify(
                            categoryScale({
                                labels: LicePerFishDailyDataSets.labels
                                    .filter((label) => {
                                        return label !== undefined;
                                    })
                                    .map((label) => label?.toString()) as string[],
                                xAxsisUnitName: t('Day'),
                                tooltipUnit: '',
                                disabled: true,
                            }),
                            autoTimeScale({}),
                            StepSizeAxsis({
                                stepSize: 0.1,
                                axsisID: 'y',
                            }),
                            suggestedMaxMin({
                                min: 0,
                                max: 0.1,
                                axsisID: 'y',
                            }),
                            formatTooltip({
                                locale: i18n.language,
                                tickType: TypeNames.number,
                                descimals: 2,
                            }),
                            startYAxisAtZero({})
                        )}
                    />
                </UmerBox>

                <UmerBox>
                    <OptoBarChart
                        chartData={LicePerFishWeeklyDataSets}
                        chartName={t('Lice per fish, weekly')}
                        actionButtons={{
                            copyAsPng: true,
                            resetZoom: true,
                            downloadAsPng: true,
                            fullScreen: true,
                            timeScaleSwitch: false,
                        }}
                        chartOptions={Options(defaultBarChartOptions).modify(
                            categoryScale({
                                labels: LicePerFishWeeklyDataSets.labels
                                    .filter((label) => {
                                        return label !== undefined;
                                    })
                                    .map((label) => label?.toString()) as string[],
                                xAxsisUnitName: t('Week'),
                                tooltipUnit: '',
                                disabled: false,
                            })
                        )}
                    />
                </UmerBox>
            </div>
        </>
    );
};

export default CageLiceGraphsPageContent;
export { CageLiceGraphsPageContent };
