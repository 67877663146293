import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { DailyLiceCount, ImageFeedbackDto, Last3DaysResult } from 'services/types';

import { LiceIconSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import Loader from '@/components/atoms/Loader';
import Notice from '@/components/atoms/Notice/Notice';
import UmerBox from '@/components/atoms/UmerBox';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { UmerBoxElementStatusInfoWithTrend } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend';
import { ImageViewContainer } from '@/components/organisms/ImageViewer';
import { OptoColors } from '@/config/settings';
import { localizedDateFormat } from '@/helpers/date';
import { ImageState } from '@/hooks/image-state';

import styles from './CageLiceDetections.module.scss';

interface CageLiceDetectionsPageContentProps {
    last200: Last3DaysResult & {
        count?: number;
        countNoLice?: number;
        dayPart?: string;
        daypartId?: number;
        total?: number;
    };
    liceCount: DailyLiceCount[];
    isOptoscaleAdmin?: boolean;
    imageState: ImageState;
    onCommitImageFeedbackDto?: (imageFeedbackDto: ImageFeedbackDto) => void;
}

const CageLiceDetectionsPageContent = ({
    last200,
    liceCount,
    isOptoscaleAdmin = false,
    onCommitImageFeedbackDto = (imageFeedbackDto: ImageFeedbackDto) => {},
    imageState,
}: CageLiceDetectionsPageContentProps) => {
    const { t } = useTranslation();

    const lastFemaleAvg = liceCount[liceCount?.length - 1]?.femaleAvg;
    const SHOW_LICE_IMAGE_LIMIT = 0.02;
    const showLiceImages = lastFemaleAvg >= SHOW_LICE_IMAGE_LIMIT;

    const last = imageState.dayswithimages[imageState.dayswithimages.length - 1];

    return (
        <div className={styles.pageBoxLayout}>
            <div>
                <UmerBox>
                    {showLiceImages || isOptoscaleAdmin ? (
                        <Loader loading={imageState.loading}>
                            <ImageViewContainer
                                instanceKey={'licedetections'}
                                isOptoScaleAdmin={isOptoscaleAdmin}
                                onCommitImageFeedbackDto={onCommitImageFeedbackDto}
                                viewerConfig={{
                                    showWoundLabel: false,
                                    showLiceLabel: true,
                                    showMagnifyer: true,
                                    showHelpText: false,
                                }}
                                {...imageState}
                            />
                        </Loader>
                    ) : (
                        <Notice
                            heading={t('No lice images heading')}
                            message={t('No lice images message')}
                            variant="warning"
                        />
                    )}
                </UmerBox>
            </div>

            <UmerBox doublePadding={true}>
                <div className={styles.rightSideElementContainer}>
                    <UmerBoxElementHeader
                        headerText={t('Information')}
                        description={t('Showing images where lice has been detected.')}
                        headerIcon={LiceIconSVG}
                        headerIconColor={OptoColors.LeafGreen}
                    />
                    {last && (
                        <UmerBoxElementStatusInfoWithTrend
                            title={t('Last measurement')}
                            info={localizedDateFormat({
                                dateString: last.day,
                                onlydate: true,
                                locale: i18n.language,
                            })}
                        />
                    )}
                </div>
            </UmerBox>
        </div>
    );
};

export default CageLiceDetectionsPageContent;
export { CageLiceDetectionsPageContent };
