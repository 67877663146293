import type { ChartData, ChartDataset, Point } from 'chart.js';
import { graphSettings, hexToRGBA } from 'config/settings';
import i18next from 'i18next';
import moment from 'moment';
import type {
    BwValues,
    BwValuesDay,
    BwValuesTrend,
    ColorValues,
    ColorValuesDay,
    ColorValuesTrend,
    DailyLaksvelResult,
    DailyLiceCount,
    FeedingConversionRateResult,
    GetListResult,
    MeasurmentOverviewResult,
    TemperatureResult,
} from 'services/types';
import { isNumberOk, stringToFixedNum, toFixed } from 'utils';

import { OptoDayPartTagType } from '@/contexts/cage-filter-context';
import type { FishHealthData } from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/components/FishHealthData';

import {
    getMillisecondsFromDay,
    getSortedDaysInDataSet,
    mapDaypartTagAsIndex,
    sortDays,
} from '../commonOptoChartHelpers';

interface OverViewChartMapper {
    overview: MeasurmentOverviewResult;
    dayTags?: string[];
    inactiveSetLegend?: string[];
    graphedDayPartTags?: string[];
}

type OverViewToMixedChartMapperType = {
    labels: string[];
    datasets: ChartDataset<'line'>[];
};

export type ReportDetailToMixedChartMapperType = {
    labels: string[];
    datasets: ChartDataset<'line'>[];
};

/**
 * @param {ChartData} overview
 */
const OverViewToLivingWeightChartMapper = ({
    overview,
    inactiveSetLegend = [],
    graphedDayPartTags = [OptoDayPartTagType.Day],
}: OverViewChartMapper): OverViewToMixedChartMapperType => {
    const { days } = overview;
    const sortedDays = sortDays(days);

    const dataSetsByDayPart = mapDaypartTagAsIndex({
        days,
        chartType: 'line',
        property: 'weightAvg',
    });

    const trendDays = days.filter((day) => {
        return day.daypartTag === 'day';
    });

    const trendLine: Point[] = trendDays
        .map((day) => {
            return {
                x: getMillisecondsFromDay(day.day),
                y: day.data.weightTrend === null ? null : stringToFixedNum(day.data.weightTrend, 2),
            };
        })
        .filter((point) => point !== undefined)
        .sort((a, b) => a.x - b.x);

    const dataSets = [];
    Object.keys(dataSetsByDayPart).map((dayPart) => {
        const checkDayPartTag = `${dayPart}`;
        if (graphedDayPartTags.includes(checkDayPartTag)) {
            dataSets.push({
                label: i18next.t(`daypartTag.${dayPart}`),
                hidden: inactiveSetLegend.includes(dayPart),
                backgroundColor: hexToRGBA(graphSettings.daytagColors[dayPart], 0.8),
                borderColor: hexToRGBA(graphSettings.daytagColors[dayPart]),
                fill: false,
                showLine: false,
                data: dataSetsByDayPart[dayPart],
            });
        }
    });

    dataSets.push({
        label: i18next.t('daypartTag.trend_line'),
        hidden: inactiveSetLegend.includes('trend_line'),
        backgroundColor: hexToRGBA(graphSettings.daytagColors.trend_line, 0.8),
        borderColor: hexToRGBA(graphSettings.daytagColors.trend_line),
        fill: false,
        showLine: true,
        data: trendLine,
    });

    const mixedChartDataSets: OverViewToMixedChartMapperType = {
        labels: [...sortedDays],
        datasets: [...dataSets],
    };

    return mixedChartDataSets;
};

export interface FeedingConverstionRate {
    conversionRate: FeedingConversionRateResult;
    dayTags?: string[];
    inactiveSetLegend?: string[];
}
const FeedingConverstionRateChartMapper = ({
    conversionRate,
    inactiveSetLegend = [],
}: FeedingConverstionRate): OverViewToMixedChartMapperType => {
    const { days } = conversionRate || { days: [] };

    const labels = days.map((day) => {
        return day.day;
    });

    const DataSet = {
        label: i18next.t('bFCR'),
        hidden: inactiveSetLegend.includes('FCR'),
        backgroundColor: hexToRGBA(graphSettings.colors.primary, 0.8),
        borderColor: hexToRGBA(graphSettings.colors.primary),
        fill: false,
        showLine: false,
        data: days
            .map((day) => ({
                x: getMillisecondsFromDay(day.day),
                y: isNumberOk(day.fcr) && day.fcr > 0 ? toFixed(day.fcr, 2) : null,
            }))
            .filter((data) => data.y !== null) as Point[],
    };

    return {
        labels: labels,
        datasets: [DataSet],
    };
};

/**
 *
 * @param overview
 * uses : days.data.cfAvg
 */
const OverViewToConditionFactorChartMapper = ({
    overview,
}: OverViewChartMapper): OverViewToMixedChartMapperType => {
    const { days } = overview;

    const sortedDays = sortDays(days);

    const dataSet = {
        label: i18next.t('CF'),
        backgroundColor: hexToRGBA(graphSettings.colors.primary, 0.8),
        borderColor: hexToRGBA(graphSettings.colors.primary),
        fill: false,
        showLine: true,
        data: days
            .filter((day) => {
                return day.daypartTag === 'all';
            })
            .map((day) => {
                return {
                    x: getMillisecondsFromDay(day.day),
                    y: stringToFixedNum(day.data.cfAvg, 4),
                };
            }),
    };

    return {
        labels: sortedDays,
        datasets: [dataSet],
    };
};

const OverViewToHeightAndLengthChartMapper = ({
    overview,
}: OverViewChartMapper): OverViewToMixedChartMapperType => {
    const { days } = overview;
    const sortedDays = sortDays(days);

    const dataSets = [
        {
            label: i18next.t('Height'),
            backgroundColor: hexToRGBA(graphSettings.heightLengthColors.height, 0.8),
            borderColor: hexToRGBA(graphSettings.heightLengthColors.height),
            fill: false,
            showLine: true,
            yAxisID: 'yAxisLeft', // Do not translate
            data: days
                .filter((day) => {
                    return day.daypartTag === 'all';
                })
                .map((day) => {
                    return {
                        x: getMillisecondsFromDay(day.day),
                        y: stringToFixedNum(day.data.fishHeightAvg, 2),
                    };
                }),
        },
        {
            label: i18next.t('Length'),
            backgroundColor: hexToRGBA(graphSettings.heightLengthColors.length, 0.8),
            borderColor: hexToRGBA(graphSettings.heightLengthColors.length),
            fill: false,
            showLine: true,
            yAxisID: 'yAxisRight', // Do not translate
            data: days
                .filter((day) => {
                    return day.daypartTag === 'all';
                })
                .map((day) => {
                    return {
                        x: getMillisecondsFromDay(day.day),
                        y: stringToFixedNum(day.data.fishLengthAvg, 2),
                    };
                }),
        },
    ];

    return {
        labels: [...sortedDays],
        datasets: [...dataSets], // dataSets.length
    };
};

/**
 *
 * @param overview
 * uses: days.data.cv
 */
const OverViewToCoefficientOfVariantChartMapper = ({
    overview,
    inactiveSetLegend = [],
    graphedDayPartTags = [OptoDayPartTagType.Day],
}: OverViewChartMapper): ChartData<'line'> => {
    const { days } = overview;
    const sortedDays = sortDays(days);

    const dataSetsByDayPart = mapDaypartTagAsIndex({
        days,
        chartType: 'line',
        property: 'cv',
    });

    const dataSets = [];
    for (const dayPart of Object.keys(dataSetsByDayPart)) {
        if (!graphedDayPartTags.includes(dayPart)) {
            continue;
        }
        dataSets.push({
            label: i18next.t(`daypartTag.${dayPart}`),
            hidden: inactiveSetLegend.includes(dayPart),
            backgroundColor: hexToRGBA(graphSettings.daytagColors[dayPart], 0.8),
            borderColor: hexToRGBA(graphSettings.daytagColors[dayPart]),
            fill: false,
            showLine: false,
            data: dataSetsByDayPart[dayPart],
        });
    }

    return {
        labels: sortedDays,
        datasets: [...dataSets],
    };
};

/**
 * @param overview
 * uses: days.data.verticalDisparity
 */
const OverViewToVerticalDisparityChartMapper = ({
    overview,
    inactiveSetLegend = [],
    graphedDayPartTags = [OptoDayPartTagType.Day],
}: OverViewChartMapper): OverViewToMixedChartMapperType => {
    const { days } = overview;

    const dataSetsByDayPart = mapDaypartTagAsIndex({
        days,
        chartType: 'line',
        property: 'verticalDisparity',
    });

    const dataSets = [];
    Object.keys(dataSetsByDayPart).map((dayPart) => {
        const checkDayPartTag = `${dayPart}`;
        if (graphedDayPartTags.includes(checkDayPartTag)) {
            dataSets.push({
                label: i18next.t(`daypartTag.${dayPart}`),
                hidden: inactiveSetLegend.includes(dayPart),
                backgroundColor: hexToRGBA(graphSettings.daytagColors[dayPart], 0.8),
                borderColor: hexToRGBA(graphSettings.daytagColors[dayPart]),
                fill: false,
                showLine: true,
                data: dataSetsByDayPart[dayPart],
            });
        }
    });

    return {
        labels: getSortedDaysInDataSet(days),
        datasets: [...dataSets],
    };
};

/**
 *
 * @param overview
 * uses: days.data.visibilityMin
 *       days.data.visibilityMax
 *       days.data.visibilityAvg
 */
const OverViewToVisibilityChartMapper = ({
    overview,
}: OverViewChartMapper): OverViewToMixedChartMapperType => {
    const { days } = overview;
    const sortedDays = sortDays(days);
    const currentDayTag = 'day';
    const dataSets = [
        {
            label: i18next.t('Average'),
            backgroundColor: hexToRGBA(graphSettings.minMaxAvgColors.avg, 0.8),
            borderColor: hexToRGBA(graphSettings.minMaxAvgColors.avg),
            fill: false,
            showLine: true,
            data: days
                .filter((day) => {
                    return day.daypartTag === currentDayTag;
                })
                .map((day) => {
                    return {
                        x: getMillisecondsFromDay(day.day),
                        y: day.data.visibilityAvg,
                    };
                }),
        },
    ];

    return {
        labels: sortedDays,
        datasets: [...dataSets],
    };
};

const OverViewToPitchRollChartMapper = ({
    overview,
}: OverViewChartMapper): OverViewToMixedChartMapperType => {
    const { days } = overview;
    const sortedDays = sortDays(days);
    const currentDayTag = 'day';
    const dataSets = [
        {
            label: i18next.t('Pitch'),
            backgroundColor: hexToRGBA(graphSettings.colors.primary, 0.8),
            borderColor: hexToRGBA(graphSettings.colors.primary),
            fill: false,
            showLine: true,
            name: 'Pitch',
            data: days
                .filter((day) => {
                    return day.daypartTag === currentDayTag;
                })
                .map((day) => {
                    return {
                        x: getMillisecondsFromDay(day.day),
                        y: day.data.bioscopePitchAvg,
                    };
                }),
        },
        {
            label: i18next.t('Roll'),
            backgroundColor: hexToRGBA(graphSettings.colors.secondary, 0.8),
            borderColor: hexToRGBA(graphSettings.colors.secondary),
            fill: false,
            showLine: true,
            name: 'Roll',
            data: days
                .filter((day) => {
                    return day.daypartTag === currentDayTag;
                })
                .map((day) => {
                    return {
                        x: getMillisecondsFromDay(day.day),
                        y: day.data.bioscopeRollAvg,
                    };
                }),
        },
    ];

    return {
        labels: sortedDays,
        datasets: [...dataSets],
    };
};

const OverViewToDepthChartMapper = ({
    overview,
    dayTags = [],
}: OverViewChartMapper): OverViewToMixedChartMapperType => {
    const { days } = overview;

    const sortedDays = sortDays(days);
    const currentDayTag = dayTags.length > 1 ? 'all' : dayTags[0];
    const dataSets = [
        {
            label: i18next.t('Min'),
            backgroundColor: hexToRGBA(graphSettings.minMaxAvgColors.min, 0.8),
            borderColor: hexToRGBA(graphSettings.minMaxAvgColors.min),
            fill: false,
            showLine: true,
            hidden: true, // Turns off visibility of the dataset and unchecks the legend checkbox
            data: days
                .filter((day) => {
                    return day.daypartTag === currentDayTag;
                })
                .map((day) => {
                    return {
                        x: getMillisecondsFromDay(day.day),
                        y: stringToFixedNum(day.data.depthMin, 2),
                    };
                }),
        },
        {
            label: i18next.t('Max'),
            backgroundColor: hexToRGBA(graphSettings.minMaxAvgColors.max, 0.8),
            borderColor: hexToRGBA(graphSettings.minMaxAvgColors.max),
            fill: false,
            showLine: true,
            hidden: true, // Turns off visibility of the dataset and unchecks the legend checkbox
            data: days
                .filter((day) => {
                    return day.daypartTag === currentDayTag;
                })
                .map((day) => {
                    return {
                        x: getMillisecondsFromDay(day.day),
                        y: stringToFixedNum(day.data.depthMax, 2),
                    };
                }),
        },
        {
            label: i18next.t('Average'),
            backgroundColor: hexToRGBA(graphSettings.minMaxAvgColors.avg, 0.8),
            borderColor: hexToRGBA(graphSettings.minMaxAvgColors.avg),
            fill: false,
            showLine: true,
            data: days
                .filter((day) => {
                    return day.daypartTag === currentDayTag;
                })
                .map((day) => {
                    return {
                        x: getMillisecondsFromDay(day.day),
                        y: stringToFixedNum(day.data.depthAvg, 2),
                    };
                }),
        },
    ];

    return {
        labels: sortedDays,
        datasets: dataSets,
    };
};

/**
 *
 * @param fullWelfareColor
 * uses: cage.list ???
 */

const WelfareColorToTypesOfWoundChartMapper = (data: {
    data: GetListResult[];
}): OverViewToMixedChartMapperType => {
    const currentDayTagId = 2;

    const dataSets = [
        {
            label: 'Active',
            backgroundColor: hexToRGBA(graphSettings.fishHealthColors.active),
            borderColor: hexToRGBA(graphSettings.fishHealthColors.active),

            fill: false,
            showLine: true,
            data: data.data
                .filter((day) => {
                    return day.daypartId === currentDayTagId;
                })
                .map((day) => {
                    return {
                        x: getMillisecondsFromDay(day.day),
                        y: day.activeWounds,
                    };
                }),
        },
        {
            label: 'Healing',
            backgroundColor: hexToRGBA(graphSettings.fishHealthColors.healing),
            borderColor: hexToRGBA(graphSettings.fishHealthColors.healing),
            fill: false,
            showLine: true,
            data: data.data
                .filter((day) => {
                    return day.daypartId === currentDayTagId;
                })
                .map((day) => {
                    return {
                        x: getMillisecondsFromDay(day.day),
                        y: day.healingWounds,
                    };
                }),
        },
    ];
    return {
        labels: data.data.map((day) => day.day),
        datasets: dataSets,
    };
};

/**
 *
 * @param overview
 * uses: days.data.weightConf ???
 */

const OverViewToGrowthChartMapper = ({
    overview,
    inactiveSetLegend = [],
    graphedDayPartTags = [OptoDayPartTagType.Day],
}: OverViewChartMapper): OverViewToMixedChartMapperType => {
    const { days } = overview;

    const labels = days.map((day) => day.day);

    const dataSetsByDayPart = mapDaypartTagAsIndex({
        days,
        chartType: 'line',
        property: 'weightGrowthAvg',
    });

    const dataSets = [];
    Object.keys(dataSetsByDayPart).map((dayPart) => {
        const checkDayPartTag = `${dayPart}`;
        if (graphedDayPartTags.includes(checkDayPartTag)) {
            dataSets.push({
                label: i18next.t(`daypartTag.${dayPart}`),
                hidden: inactiveSetLegend.includes(dayPart),
                backgroundColor: hexToRGBA(graphSettings.daytagColors[dayPart], 0.8),
                borderColor: hexToRGBA(graphSettings.daytagColors[dayPart]),
                fill: false,
                showLine: true,
                data: dataSetsByDayPart[dayPart],
            });
        }
    });

    return {
        labels: [...labels],
        datasets: [...dataSets],
    };
};

export interface ReportDetailProps {
    days: {
        day: string;
        count: number;
        livingWeight: number;
        livingWeightTrend: number;
    }[];
    weightUnitTag: string;
    lastFeedDay: string;
    slaughterDay: string;
}

const ReportDetailToGrowthChartMapper = ({
    days,
}: ReportDetailProps): ReportDetailToMixedChartMapperType => {
    const labels = days.map((day) => day.day);

    const dailyAverageSet = {
        label: i18next.t('Daily average'),
        backgroundColor: hexToRGBA(graphSettings.daytagColors.day, 0.8),
        borderColor: hexToRGBA(graphSettings.daytagColors.day),
        fill: false,
        showLine: false,
        data: [],
    };

    const trendLineSet = {
        label: i18next.t('Trend line'),
        backgroundColor: hexToRGBA(graphSettings.daytagColors.day, 0.8),
        borderColor: hexToRGBA(graphSettings.daytagColors.day),
        fill: false,
        showLine: true,
        data: [],
    };
    for (const dayObj of days) {
        const { day, livingWeight, livingWeightTrend } = dayObj;
        dailyAverageSet.data?.push({ x: getMillisecondsFromDay(day), y: livingWeight });
        trendLineSet.data?.push({ x: getMillisecondsFromDay(day), y: livingWeightTrend });
    }

    return {
        labels: [...labels],
        datasets: [trendLineSet, dailyAverageSet],
    };
};

const ComparingDataMapper = ({ data }): OverViewToMixedChartMapperType => {
    const dataSets = [];

    const labels = [];
    for (const key in data) {
        // biome-ignore lint/complexity/noForEach: <explanation>
        data[key].forEach((day) => {
            if (!labels.includes(day.day)) {
                labels.push(day.day);
            }
        });
    }

    for (const key in data) {
        dataSets.push({
            label: key,
            backgroundColor: hexToRGBA(
                graphSettings.comparingPensColors[dataSets.length] ?? '#1E90FF',
                0.8
            ),
            borderColor: hexToRGBA(graphSettings.comparingPensColors[dataSets.length] ?? '#1E90FF'),
            fill: false,
            showLine: true,
            data: labels
                .map((l) => {
                    return {
                        x: getMillisecondsFromDay(l),
                        y: data[key].find((day) => day.day === l)?.value,
                    };
                })
                .sort((a, b) => a.x - b.x),
        });
    }

    return {
        labels: [...labels],
        datasets: [...dataSets],
    };
};

export interface FishHealthChartMapper {
    fishHealth: FishHealthData;
    source?: string;
    dayTags?: string[];
    inactiveSetLegend?: string[];
}

const FishHealthToTypesOfWoundsMapper = ({
    fishHealth,
}: FishHealthChartMapper): OverViewToMixedChartMapperType => {
    const legendLabels = ['activeWounds', 'healingWounds', 'temperature'];
    const { days } = fishHealth;
    const labels = days.map((day) => day.day);

    const dataSet = [];

    dataSet.push({
        label: i18next.t(`fishHealthIndicators.${legendLabels[0]}`),
        backgroundColor: hexToRGBA(graphSettings.fishHealthColors.active),
        borderColor: hexToRGBA(graphSettings.fishHealthColors.active),
        fill: false,
        //name: legendLabels[0],
        yAxisID: 'yAxisLeft',
        showLine: true,
        data: days.map((day) => {
            return {
                x: getMillisecondsFromDay(day.day),
                y:
                    day.hasActiveWounds !== undefined
                        ? Number(day.hasActiveWounds) * 100
                        : undefined,
            } as Point;
        }),
    });

    dataSet.push({
        label: i18next.t(`fishHealthIndicators.${legendLabels[1]}`),
        backgroundColor: hexToRGBA(graphSettings.fishHealthColors.healing, 0.6),
        borderColor: hexToRGBA(graphSettings.fishHealthColors.healing, 0.6),
        fill: false,
        yAxisID: 'yAxisLeft',
        //name: legendLabels[1],
        showLine: true,
        data: days.map((day) => {
            return {
                x: getMillisecondsFromDay(day.day),
                y:
                    day?.hasHealingAndNotActiveWounds !== undefined
                        ? Number(day?.hasHealingAndNotActiveWounds) * 100
                        : undefined,
            } as Point;
        }),
    });

    dataSet.push({
        label: i18next.t('Temperature'),
        backgroundColor: hexToRGBA(graphSettings.fishHealthColors.temperature, 0.8),
        borderColor: hexToRGBA(graphSettings.fishHealthColors.temperature, 0.8),
        name: legendLabels[2],
        yAxisID: 'yAxisRight',
        fill: true,
        data: days.map((day) => {
            return { x: getMillisecondsFromDay(day.day), y: day.temperature } as Point;
        }),
    });

    return {
        labels: [...labels],
        datasets: [...dataSet],
    };
};

const FishHealthToSkinSpecklesMapper = ({
    fishHealth,
}: FishHealthChartMapper): OverViewToMixedChartMapperType => {
    const legendLabels = ['skinSpeckles'];
    const { days, trend } = fishHealth;
    const labels = days.map((day) => day.day);

    const trendLine: Point[] = days
        .map((day) => {
            const yPoint = trend.filter(
                (p) => getMillisecondsFromDay(p?.day) === getMillisecondsFromDay(day?.day)
            );
            return {
                x: getMillisecondsFromDay(day.day),
                y: yPoint.length > 0 ? yPoint[0].skinSpeckles * 100 : null,
            } as Point;
        })
        .filter((point) => point !== undefined)
        .sort((a, b) => a.x - b.x);

    const dataSet = [];
    dataSet.push({
        label: i18next.t(`fishHealthIndicators.${legendLabels[0]}`),
        backgroundColor: hexToRGBA(graphSettings.colors.primary, 1),
        borderColor: hexToRGBA(graphSettings.colors.primary),
        fill: false,
        showLine: true,
        data: days.map((day) => {
            return { x: getMillisecondsFromDay(day.day), y: day.skinSpeckles * 100 };
        }),
    });

    dataSet.push({
        label: i18next.t('daypartTag.trend_line'),
        backgroundColor: hexToRGBA(graphSettings.daytagColors.trend_line, 0.8),
        borderColor: hexToRGBA(graphSettings.daytagColors.trend_line),
        fill: false,
        showLine: true,
        data: trendLine,
    });

    return {
        labels: [...labels],
        datasets: [...dataSet],
    };
};

//Daily development of maturation

type Daysdata = { day: string };

type OnlyNumbers<T> = {
    [K in keyof T]: T[K] extends number ? K : never;
};

interface GenericMapperProps<T extends Daysdata> {
    keys: {
        label: string;
        color: string;
        fill?: boolean;
        showLine?: boolean;
        source: (keyof OnlyNumbers<T> & string) | ((day: T) => number);
    }[];

    days: T[];
}

function GenericMapper<T extends Daysdata>({ keys, days }: GenericMapperProps<T>) {
    const labels = days.map((day) => day.day);

    const datasets = keys.map((key) => {
        const source = key.source;
        const fetcher: (day: T) => number =
            typeof source === 'string' ? (day: T) => day[source] as number : source;
        return {
            label: key.label,
            backgroundColor: hexToRGBA(key.color, 0.8),
            borderColor: hexToRGBA(key.color),
            fill: false,
            showLine: true,
            data: days.map((day) => {
                return { x: getMillisecondsFromDay(day.day), y: fetcher(day) };
            }),
        };
    });

    return {
        labels,
        datasets,
    };
}

const FullMaturationMapper = ({ fishHealth }: FishHealthChartMapper) => {
    return GenericMapper({
        keys: [
            {
                label: i18next.t('fishHealthIndicators.fullyMature'),
                source: (day) => day.fullyMature * 100,
                color: graphSettings.colors.primary,
            },
        ],
        days: fishHealth.days,
    });
};

const MaturationDailyDevelopmentMapper = ({
    fishHealth,
    source,
}: FishHealthChartMapper): OverViewToMixedChartMapperType => {
    const { days } = fishHealth;

    const labels = days.map((day) => day.day);
    const dataSets = [];

    dataSets.push({
        label: i18next.t('Full'),
        backgroundColor: hexToRGBA(graphSettings.colors.primary, 0.8),
        borderColor: hexToRGBA(graphSettings.colors.primary, 0.8),
        fill: true,
        showLine: true,
        data: days.map((day) => {
            return {
                x: getMillisecondsFromDay(day.day),
                y: source === 'color' ? day?.fullyMature * 100 : day?.matureJaw * 100,
            };
        }),
    });

    return {
        labels: [...labels],
        datasets: [...dataSets],
    };
};

const FishHealthToMaturationMapper = ({
    fishHealth,
    source,
}: FishHealthChartMapper): OverViewToMixedChartMapperType => {
    const { days } = fishHealth;
    const labels = days.map((day) => day.day);
    const dataSet = {
        label: i18next.t('Maturation'),
        backgroundColor: hexToRGBA(graphSettings.colors.primary, 0.8),
        borderColor: hexToRGBA(graphSettings.colors.primary),
        fill: false,
        showLine: true,
        data: days.map((day) => {
            return {
                x: getMillisecondsFromDay(day.day),
                y: source === 'color' ? day.startedMaturation * 100 : day.matureJaw * 100,
            };
        }),
    };
    return {
        labels: [...labels],
        datasets: [dataSet],
    };
};

const FishHealthToSizeDistributionnMapper = ({
    fishHealth,
}: FishHealthChartMapper): OverViewToMixedChartMapperType => {
    const { days } = fishHealth;
    const legendLabels = ['bigWound', 'mediumWound', 'smallWound'];
    const labels = days.map((day) => day.day);

    const dataSets = [];

    dataSets.push({
        label: i18next.t(`fishHealthIndicators.${legendLabels[0]}`),
        backgroundColor: hexToRGBA(graphSettings.fishHealthColors.large, 0.8),
        borderColor: hexToRGBA(graphSettings.fishHealthColors.large),
        fill: false,
        yAxisID: 'yAxisLeft',
        showLine: true,
        data: days.map((day) => {
            return { x: getMillisecondsFromDay(day.day), y: day.bigWound * 100 };
        }),
    });

    dataSets.push({
        label: i18next.t(`fishHealthIndicators.${legendLabels[1]}`),
        backgroundColor: hexToRGBA(graphSettings.fishHealthColors.medium, 0.8),
        borderColor: hexToRGBA(graphSettings.fishHealthColors.medium),
        fill: false,
        yAxisID: 'yAxisLeft',
        showLine: true,
        data: days.map((day) => {
            return { x: getMillisecondsFromDay(day.day), y: day.mediumWound * 100 };
        }),
    });

    dataSets.push({
        label: i18next.t(`fishHealthIndicators.${legendLabels[2]}`),
        backgroundColor: hexToRGBA(graphSettings.fishHealthColors.small, 0.8),
        borderColor: hexToRGBA(graphSettings.fishHealthColors.small),
        fill: false,
        yAxisID: 'yAxisLeft',
        showLine: true,
        data: days.map((day) => {
            return { x: getMillisecondsFromDay(day.day), y: day.smallWound * 100 };
        }),
    });

    dataSets.push({
        label: i18next.t('Temperature'),
        backgroundColor: hexToRGBA(graphSettings.fishHealthColors.temperature, 0.8),
        borderColor: hexToRGBA(graphSettings.fishHealthColors.temperature, 0.8),
        name: legendLabels[2],
        yAxisID: 'yAxisRight',
        fill: true,
        data: days.map((day) => {
            return { x: getMillisecondsFromDay(day.day), y: day.temperature } as Point;
        }),
    });

    return {
        labels: [...labels],
        datasets: [...dataSets],
    };
};

const FishHealthToColorMaturationMapper = ({
    fishHealth,
}: FishHealthChartMapper): OverViewToMixedChartMapperType => {
    const { days } = fishHealth;
    const legendLabels = ['fullMaturation', 'startedMaturation'];
    const labels = days.map((day) => day.day);

    const dataSets = [];
    dataSets.push({
        label: legendLabels[0],
        backgroundColor: hexToRGBA(graphSettings.fishHealthColors.large, 0.8),
        borderColor: hexToRGBA(graphSettings.fishHealthColors.large),
        fill: false,
        showLine: true,
        data: days.map((day) => {
            return { x: getMillisecondsFromDay(day.day), y: day.fullyMature * 100 };
        }),
    });

    dataSets.push({
        label: legendLabels[1],
        backgroundColor: hexToRGBA(graphSettings.fishHealthColors.medium, 0.8),
        borderColor: hexToRGBA(graphSettings.fishHealthColors.medium),
        fill: false,
        showLine: true,
        data: days.map((day) => {
            return { x: getMillisecondsFromDay(day.day), y: day.startedMaturation * 100 };
        }),
    });

    return {
        labels: [...labels],
        datasets: [...dataSets],
    };
};

const FishHealthToDistributionOfWoundsMapper = ({
    fishHealth,
}: FishHealthChartMapper): OverViewToMixedChartMapperType => {
    const { days } = fishHealth;
    const legendLabels = ['oneWound', 'moreThanOneWound'];
    const labels = days.map((day) => day.day);

    const dataSets = [];
    dataSets.push({
        label: i18next.t(`fishHealthIndicators.${legendLabels[0]}`),
        backgroundColor: hexToRGBA(graphSettings.fishHealthColors.singleWound, 0.8),
        borderColor: hexToRGBA(graphSettings.fishHealthColors.singleWound),
        fill: false,
        yAxisID: 'yAxisLeft',
        showLine: true,
        data: days.map((day) => {
            return { x: getMillisecondsFromDay(day.day), y: day.oneWound * 100 };
        }),
    });

    dataSets.push({
        label: i18next.t(`fishHealthIndicators.${legendLabels[1]}`),
        backgroundColor: hexToRGBA(graphSettings.fishHealthColors.multipleWounds, 0.8),
        borderColor: hexToRGBA(graphSettings.fishHealthColors.multipleWounds),
        fill: false,
        yAxisID: 'yAxisLeft',
        showLine: true,
        data: days.map((day) => {
            return { x: getMillisecondsFromDay(day.day), y: day.moreThanOneWound * 100 };
        }),
    });

    dataSets.push({
        label: i18next.t('Temperature'),
        backgroundColor: hexToRGBA(graphSettings.fishHealthColors.temperature, 0.8),
        borderColor: hexToRGBA(graphSettings.fishHealthColors.temperature, 0.8),
        name: legendLabels[2],
        yAxisID: 'yAxisRight',
        fill: true,
        data: days.map((day) => {
            return { x: getMillisecondsFromDay(day.day), y: day.temperature } as Point;
        }),
    });

    return {
        labels: [...labels],
        datasets: [...dataSets],
    };
};

const OverViewToSceneLabelChartMapper = ({
    overview,
    dayTags = ['day'],
}: OverViewChartMapper): OverViewToMixedChartMapperType => {
    const sceneLabelTags = [
        'badangle',
        'chaos',
        'empty',
        'faraway',
        'net',
        'rope',
        'school',
        'singlefish',
        'tooclose',
    ];
    const { days } = overview;
    const filterDaysByDayTag = days.filter((day) => day.daypartTag === dayTags[0]);
    const labels = filterDaysByDayTag.map((day) => day.day);
    const dataSets = [];

    for (let i = 0; i < sceneLabelTags.length; i++) {
        const dataSet = {
            label: sceneLabelTags[i],
            backgroundColor: hexToRGBA(graphSettings.SceneLabelTagsColors[sceneLabelTags[i]], 0.8),
            borderColor: hexToRGBA(graphSettings.SceneLabelTagsColors[sceneLabelTags[i]]),
            fill: true,
            showLine: true,
            data: filterDaysByDayTag.map((day) => {
                return {
                    x: getMillisecondsFromDay(day.day),
                    y: day.sceneLabelRelativeCnt?.[i] ? day.sceneLabelRelativeCnt[i] * 100 : null,
                };
            }),
        };

        dataSets.push(dataSet);
    }
    return {
        labels: [...labels],
        datasets: [...dataSets],
    };
};

/**
 * @param overview
 * uses: days.data.verticalDisparity
 */
const OverViewToFishSpeedChartMapper = ({
    overview,
    inactiveSetLegend = [],
    graphedDayPartTags = [OptoDayPartTagType.Day],
}: OverViewChartMapper): OverViewToMixedChartMapperType => {
    const { days } = overview;

    const dataSetsByDayPart = mapDaypartTagAsIndex({
        days,
        chartType: 'line',
        property: 'speed',
    });

    const dataSets = [];
    Object.keys(dataSetsByDayPart)?.map((dayPart) => {
        if (graphedDayPartTags.includes(dayPart)) {
            return dataSets.push({
                label: i18next.t(`daypartTag.${dayPart}`),
                hidden: inactiveSetLegend.includes(dayPart),
                backgroundColor: hexToRGBA(graphSettings.daytagColors[dayPart], 0.8),
                borderColor: hexToRGBA(graphSettings.daytagColors[dayPart]),
                fill: false,
                showLine: true,
                name: 'Fish speed',
                data: dataSetsByDayPart[dayPart],
            });
        }
    });

    return {
        labels: getSortedDaysInDataSet(days),
        datasets: [...dataSets],
    };
};

export interface CombinedFishHealthWithTemperatureDataProps {
    combinedFishHealthData: {
        countForStats?: number | undefined;
        days?: BwValuesDay[] | ColorValuesDay[] | undefined;
        lastDay?: string | undefined;
        lastEstimate?: BwValues | ColorValues | undefined;
        slopePerDay?: BwValues | ColorValues | undefined;
        trend?: BwValuesTrend[] | ColorValuesTrend[] | undefined;
    };
    temperature: TemperatureResult[];
}

export const CombinedFishHealthWithTemperatureData = ({
    combinedFishHealthData,
    temperature,
}: CombinedFishHealthWithTemperatureDataProps) => {
    return {
        ...combinedFishHealthData,
        days: combinedFishHealthData?.days?.map((day) => ({
            ...day,
            temperature:
                temperature?.find((temp) => moment(temp.day).format('YYYY-MM-DD') === day.day)
                    ?.temperature ?? undefined,
        })),
    };
};

type LiceCountToMixedChartMapperType = {
    labels: string[];
    datasets: ChartDataset<'line'>[];
};

export interface LiceCountTemperatureToMixedChartMapper {
    data: {
        temperature: TemperatureResult[];
        liceCount: DailyLiceCount[];
    };
    inactiveSetLegend?: string[];
    chartDataPoints?: string[];
}

const LiceCountTemperatureMixedChartMapper = ({
    data,
    chartDataPoints = ['adult_female', 'mobile', 'caligus'],
}: LiceCountTemperatureToMixedChartMapper): LiceCountToMixedChartMapperType => {
    const { temperature, liceCount } = data;

    const LiceCountDataWithTemperature = liceCount?.map((el) => {
        return {
            ...el,
            temperature: temperature?.find((e) => e.day === el.day)?.temperature ?? null,
        };
    });

    const labels = LiceCountDataWithTemperature.map((day: DailyLiceCount) => day.day).sort();
    const dataSets = [];

    if (chartDataPoints.includes('adult_female')) {
        dataSets.push({
            label: i18next.t('Mature female lice'),
            backgroundColor: hexToRGBA(graphSettings.graphColors.adult_female, 0.8),
            borderColor: hexToRGBA(graphSettings.graphColors.adult_female),
            fill: false,
            showLine: true,
            yAxisID: 'yAxisLeft', // Do not translate
            data: LiceCountDataWithTemperature.map((day: DailyLiceCount) => {
                return {
                    x: getMillisecondsFromDay(day.day),
                    y: day.femaleAvg,
                };
            }).sort((a, b) => a.x - b.x) as Point[],
        });
    }

    if (chartDataPoints.includes('mobile')) {
        dataSets.push({
            label: i18next.t('Mobile'),
            backgroundColor: hexToRGBA(graphSettings.graphColors.mobile, 0.8),
            borderColor: hexToRGBA(graphSettings.graphColors.mobile),
            fill: false,
            showLine: true,
            yAxisID: 'yAxisLeft', // Do not translate
            data: LiceCountDataWithTemperature.map((day: DailyLiceCount) => {
                return {
                    x: getMillisecondsFromDay(day.day),
                    y: day.movingStageAvg,
                };
            }).sort((a, b) => a.x - b.x) as Point[],
        });
    }

    if (chartDataPoints.includes('caligus')) {
        dataSets.push({
            label: i18next.t('Caligus'),
            backgroundColor: hexToRGBA(graphSettings.graphColors.caligus, 0.8),
            borderColor: hexToRGBA(graphSettings.graphColors.caligus),
            fill: false,
            showLine: true,
            yAxisID: 'yAxisLeft', // Do not translate
            data: LiceCountDataWithTemperature.map((day: DailyLiceCount) => {
                return {
                    x: getMillisecondsFromDay(day.day),
                    y: day.caligusElongatusAvg,
                };
            }).sort((a, b) => a.x - b.x) as Point[],
        });
    }

    dataSets.push({
        label: i18next.t('Temperature'),
        backgroundColor: hexToRGBA(graphSettings.graphColors.temperature, 0.2),
        borderColor: hexToRGBA(graphSettings.graphColors.temperature),
        fill: true,
        showLine: true,
        yAxisID: 'yAxisRight', // Do not translate
        data: LiceCountDataWithTemperature.map((day) => {
            return {
                x: getMillisecondsFromDay(day.day),
                y: day.temperature !== null ? day.temperature : undefined,
            };
        }).sort((a, b) => a.x - b.x) as Point[],
    });

    return {
        datasets: [...dataSets],
        labels: [...labels],
    };
};

interface LaksvelFirstImpressionLineChartMapperProps {
    data?: DailyLaksvelResult[];
}
const LaksvelFirstImpressionLineChartMapper = ({
    data = [],
}: LaksvelFirstImpressionLineChartMapperProps): OverViewToMixedChartMapperType => {
    const colors: { [key: string]: string } = {
        body_wounds: '#4579A3',
        cataract: '#5BB784',
        deformed_jaw: '#C65461',
        emaciation: '#FFD479',
        eye_damage: '#696999',
        fin_damage: '#559DA3',
        gill_lid: '#E87166',
        maturity: '#B6C377',
        snout_wounds: '#8D6A99',
        deformity: '#F4A46F',
        skin_bleeding: '#CE7997',
        scale_loss: '#80B580',
    };
    const labels = data.map((day) => day.day);
    const laksvelParameterNames = data.length
        ? Object.keys(data[0]).filter((key) => key !== 'day' && key !== 'cnt')
        : [];

    const dataSets = [];

    const laksvelData = data.reduce((acc, day) => {
        laksvelParameterNames.forEach((prop: string) => {
            if (day[prop] !== undefined) {
                if (!acc[prop]) {
                    acc[prop] = [];
                }
                acc[prop].push({
                    x: getMillisecondsFromDay(day.day),
                    y: day[prop].trend,
                });
            }
        });
        return acc;
    }, {});

    Object.keys(laksvelData).forEach((prop) => {
        dataSets.push({
            label: i18next.t(`${prop}`),
            backgroundColor: hexToRGBA(colors[prop], 0.8),
            borderColor: hexToRGBA(colors[prop]),
            fill: false,
            showLine: true,
            data: laksvelData[prop],
        });
    });

    return {
        labels: [...labels],
        datasets: [...dataSets],
    };
};

interface DailyLaksvelMapperProps {
    data?: DailyLaksvelResult[];
    laksvelAttribute: string;
}
const DailyLaksvelMapper = ({ data = [], laksvelAttribute }: DailyLaksvelMapperProps) => {
    const labels: string[] = ['score_1', 'score_2', 'score_3'];
    const colors: { [key: string]: string } = {
        score_1: '#F9C975',
        score_2: '#F58C55',
        score_3: '#F55C47',
    };
    const sortedDays = sortDays(data);

    const dataSets = [];

    for (const label of labels) {
        dataSets.push({
            label: i18next.t(`scores.${label}`),
            backgroundColor: hexToRGBA(colors[label], 0.8),
            borderColor: hexToRGBA(colors[label]),
            fill: false,
            showLine: true,
            data: data
                .map((day) => {
                    const scores = day[laksvelAttribute as keyof DailyLaksvelResult] ?? {};

                    return {
                        x: getMillisecondsFromDay(day.day),
                        y: (scores as { [key: string]: { percent: number } })[label]?.percent ?? 0,
                    } as Point;
                })
                .sort((a, b) => a.x - b.x),
        });
    }

    const dailySet = {
        labels: [...sortedDays],
        datasets: [...dataSets],
    };

    return dailySet;
};

export {
    OverViewToLivingWeightChartMapper,
    OverViewToConditionFactorChartMapper,
    OverViewToCoefficientOfVariantChartMapper,
    OverViewToGrowthChartMapper,
    OverViewToVerticalDisparityChartMapper,
    OverViewToVisibilityChartMapper,
    ReportDetailToGrowthChartMapper,
    FeedingConverstionRateChartMapper,
    OverViewToHeightAndLengthChartMapper,
    OverViewToDepthChartMapper,
    WelfareColorToTypesOfWoundChartMapper,
    FishHealthToMaturationMapper,
    FishHealthToSizeDistributionnMapper,
    FishHealthToTypesOfWoundsMapper,
    FishHealthToColorMaturationMapper,
    FishHealthToDistributionOfWoundsMapper,
    MaturationDailyDevelopmentMapper,
    FishHealthToSkinSpecklesMapper,
    FullMaturationMapper,
    OverViewToSceneLabelChartMapper,
    OverViewToFishSpeedChartMapper,
    OverViewToPitchRollChartMapper,
    ComparingDataMapper,
    LiceCountTemperatureMixedChartMapper,
    LaksvelFirstImpressionLineChartMapper,
    DailyLaksvelMapper,
};

/*
        "day": "2024-09-02T00:00:00.000Z",
        "maturity": {
            "score_0": {
                "percent": 21.85,
                "raw": 0.2185152452496686
            },


        "day": "2024-09-02T00:00:00.000Z",
        "maturity": {
            "score_0": {
                "percent": 14.66,
                "raw": 0.1465913104909926
            },

*/
